import { render, staticRenderFns } from "./SmsCalls.vue?vue&type=template&id=abe820a6&scoped=true&"
import script from "./SmsCalls.vue?vue&type=script&lang=js&"
export * from "./SmsCalls.vue?vue&type=script&lang=js&"
import style0 from "./SmsCalls.vue?vue&type=style&index=0&id=abe820a6&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "abe820a6",
  null
  
)

export default component.exports