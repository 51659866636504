<template>
  <div class="SmsCalls">
    <div v-if="!general">
      <div class="description">
        <!--          1 represents the account owner-->
        You can specify up to {{user.is_on_old_plan ? user.subscription_plan.teammates : 1 + (user.limits ? user.limits.notify_only_seats : user.subscription_plan.notify_only_seats)}} phone numbers to which we will send notifications. This number is equal to the subscription's number of teammates
      </div>
      <br>
      <div v-if="monitor.phone_numbers">
        <div v-for="(phone_number, index) in monitor.phone_numbers" :key="index">
          <div v-if="phone_number">
            <div class="phone-numbers-list" v-bind:class="{'prevent-click': deleting}">
              <div class="remove"
                   @click.prevent="deletePhoneNumber(index)">
                <icon-trash width="18"
                            height="18" />
              </div>
              <div class="text">{{ phone_number.phone_number }}</div>

              <base-checkbox label="SMS"
                             class="m-2"
                             v-model="phone_number.sms"
                             @input="updatePhoneNumber(phone_number)"/>
              <base-checkbox label="Calls"
                             class="m-2"
                             v-model="phone_number.calls"
                             @input="updatePhoneNumber(phone_number)"/>
              <sms-calls-test :general="general" :phone_number="phone_number" @error="testingError"></sms-calls-test>
              <p>You have {{user.tests_per_day}} tests.</p>
            </div>
            <br>
          </div>
        </div>
      </div>
      <base-alert type="error" v-if="errorMessage">
        {{errorMessage}}
      </base-alert>
    </div>
    <div v-if="general">
      <div class="description">
        <!--          1 represents the account owner-->
        You can specify up to {{user.is_on_old_plan ? user.subscription_plan.teammates : 1 + (user.limits ? user.limits.notify_only_seats : user.subscription_plan.notify_only_seats)}} phone numbers to which we will send notifications. This number is equal to the subscription's number of teammates
      </div>
      <br>
      <div v-for="number in userGeneralNotificationChannels" :key="number.item_value">
        <div v-if="number">
          <div class="phone-numbers-list" v-bind:class="{'prevent-click': deleting}">
            <div class="remove"
                 @click.prevent="deleteGeneralChannel(number.id)">
              <icon-trash width="18"
                          height="18" />
            </div>
            <div class="text">{{ number.item_value }}</div>

            <base-checkbox label="SMS"
                           class="m-2"
                           v-model="number.sms"
                           @input="updateGeneralPhoneNumber({'number': number.item_value, 'sms': number.sms, 'calls': number.calls})"/>
            <base-checkbox label="Calls"
                           class="m-2"
                           v-model="number.calls"
                           @input="updateGeneralPhoneNumber({'number': number.item_value, 'sms': number.sms, 'calls': number.calls})"/>
            <sms-calls-test :general="general" :phone_number="number.item_value" @error="testingError"></sms-calls-test>
            <p>You have {{user.tests_per_day}} tests.</p>
            </div>
          <br>
        </div>
      </div>
      <base-alert type="error" v-if="generalError && generalHyperlink">
        You have reached the phone numbers limit on monitor: <a :href=generalHyperlink>{{ generalError }}</a>
      </base-alert>
      <base-alert type="error" v-else-if="generalError">
        {{ generalError }}
      </base-alert>
    </div>

    <ValidationObserver ref="observer" v-slot="{ invalid, passes }">
      <form ref="form" @submit.prevent="passes(onFormSubmit)">
        <div class="new-phone-number">
          <base-phone-number ref="phoneNumber"
                             placeholder="Phone number"
                             rules="required|mobilePhone"
                             v-model="phoneNumber"
                              @countryChanged="onCountryChanged"/>
          <base-button type="submit"
                       class="add ml-2"
                       color="primary"
                       :loading="submitting">Add Phone Number</base-button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import phoneNumbersApi from '@/api/monitorNotifications/phoneNumbersApi'
import usersApi from '../../../api/usersApi'
import notificationChannelsApi from '../../../api/monitorNotifications/notificationChannelsApi'
import SmsCallsTest from './SmsCallsTest'
import BaseAlert from '@/components/Base/BaseAlert.vue'

export default {
  components: { BaseAlert, SmsCallsTest },
  props: {
    monitor: {
      required: false,
      type: Object
    },
    general: {
      required: false,
      default: false,
      type: Boolean
    }
  },

  data () {
    return {
      phoneNumber: '',
      submitting: false,
      testing: false,
      deleting: false,
      errorMessage: '',
      generalError: false,
      generalHyperlink: false,
      userGeneralNotificationChannels: []
    }
  },

  created () {
    if (this.general) {
      this.loadGeneralNotificationChannels()
    }
  },

  methods: {
    async onFormSubmit () {
      if (this.general) {
        try {
          const body = { phoneNumber: this.phoneNumber }
          this.submitting = true
          await phoneNumbersApi.generalAddPhoneNumber(body)
          await this.loadGeneralNotificationChannels()
        } catch (e) {
          this.generalError = e.response.data.errorMonitor ?? 'Something went wrong'
          this.generalHyperlink = e.response.data.hyperlink ?? false
        }
        this.submitting = false
        this.$refs.observer.reset()
        this.phoneNumber = ''
      } else {
        this.errorMessage = ''
        try {
          const body = { phoneNumber: this.phoneNumber }
          this.submitting = true
          const response = await phoneNumbersApi.addPhoneNumber(this.monitor.id, body)
          this.monitor.phone_numbers.push(response.data)
        } catch (e) {
          console.error(e.response)
          this.errorMessage = e.response.data.message
        }
        this.submitting = false
        this.$refs.observer.reset()
        this.phoneNumber = ''
      }
    },

    async deletePhoneNumber (phoneNumberIndex) {
      this.errorMessage = ''
      this.deleting = true
      try {
        await phoneNumbersApi.deletePhoneNumber(this.monitor.phone_numbers[phoneNumberIndex].id)
        this.monitor.phone_numbers[phoneNumberIndex] = null
      } catch (e) {
        console.error(e.response)
      }
      this.deleting = false
    },

    async updatePhoneNumber (number) {
      this.errorMessage = ''
      try {
        const body = {
          sms: number.sms,
          calls: number.calls,
          phoneNumber: number.phone_number
        }
        const response = await phoneNumbersApi.updatePhoneNumber(this.monitor.id, body)
        this.monitor.phone_number = response.data
      } catch (e) {
        console.error(e.response)
      }
    },

    async deleteGeneralChannel (channels) {
      for (let channel of channels) {
        await notificationChannelsApi.generalDelete(channel)
      }
      await this.loadGeneralNotificationChannels()
    },

    async loadGeneralNotificationChannels () {
      try {
        const response = await usersApi.getGeneralChannels('monitor_phone_number')
        this.userGeneralNotificationChannels = []
        for (let item of response) {
          let index = this.userGeneralNotificationChannels.findIndex(obj => obj.item_value === item.item_value)
          if (index === -1) {
            this.userGeneralNotificationChannels.push({
              'id': [item.id],
              'sms': item.item_key.includes('sms'),
              'calls': item.item_key.includes('calls'),
              'item_value': item.item_value
            })
          } else {
            this.userGeneralNotificationChannels[index].id.push(item.id)
            this.userGeneralNotificationChannels[index].calls = true
            this.userGeneralNotificationChannels[index].sms = true
          }
        }
      } catch (e) {
        this.generalError = 'Something went wrong'
      }
    },

    async updateGeneralPhoneNumber (data) {
      await phoneNumbersApi.updateGeneralPhoneNumberNumber(data)
      await this.loadGeneralNotificationChannels()
    },

    onCountryChanged (country) {
      this.errorMessage = ''
      if (!this.phoneNumber) {
        this.phoneNumber = '+' + country.dialCode
      }
    },

    testingError (value) {
      this.errorMessage = value
    },

    ...mapActions('authentication', [
      'refreshCurrentUser'
    ])
  },

  computed: {
    ...mapState('authentication', ['user'])
  }
}
</script>
<style scoped lang="scss">
  .SmsCalls {
    .new-phone-number {
        display: flex;
        max-width: 550px;;

        /deep/ .base-phone-number {
          flex-grow: 4;
          margin: 0;
        }
    }

    .phone-numbers-list {
      display: flex;
      align-items: center;

      .remove {
        cursor: pointer;
        padding: 10px 10px 10px 0;

        &:hover /deep/ svg g {
          transition: 0.2s all ease;
          fill: map-get($colors, purple-1) !important;
        }
      }

      .text {
        margin-bottom: 3px;
      }
    }

    .prevent-click {
      pointer-events: none;
    }
  }
</style>
