<template>
  <base-button v-if="user.tests_per_day > 0 && !general"
               class="ml-2 mr-3"
               color="primary"
               :loading="testing"
               @click="testPhoneNumber(phone_number)">Test</base-button>
  <base-button v-else-if="user.tests_per_day > 0 && general"
               class="ml-2 mr-3"
               color="primary"
               :loading="testing"
               @click="testGeneralPhoneNumber(phone_number)">Test</base-button>

</template>

<script>
import phoneNumbersApi from '../../../api/monitorNotifications/phoneNumbersApi'

export default {
  props: {
    general: {
      required: true,
      default: false,
      type: Boolean
    },
    phone_number: {
      required: true,
      type: String || Object
    }
  },

  data () {
    return {
      testing: false,
      errorMessage: ''
    }
  },

  methods: {

    async testPhoneNumber (phoneNumber) {
      this.errorMessage = ''
      this.testing = true
      try {
        await phoneNumbersApi.testPhoneNumber(phoneNumber.id)
      } catch (e) {
        console.error(e.response.data)
        if (typeof e.response.data !== 'undefined') {
          this.errorMessage = e.response.data.message
        }
      }
      await this.refreshCurrentUser()
      this.testing = false
      this.$emit('error', this.errorMessage)
    },

    async testGeneralPhoneNumber (phoneNumber) {
      this.errorMessage = ''
      this.testing = true
      const data = { 'phone_number': phoneNumber }
      try {
        await phoneNumbersApi.testGeneralPhoneNumber(data)
      } catch (e) {
        console.error(e.response.data)
        if (typeof e.response.data !== 'undefined') {
          this.errorMessage = e.response.data.message
        }
      }
      await this.refreshCurrentUser()
      this.testing = false
      this.$emit('error', this.errorMessage)
    },

    ...mapActions('authentication', [
      'refreshCurrentUser'
    ])
  },

  computed: {
    ...mapState('authentication', ['user'])
  }
}
</script>
